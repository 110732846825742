<template>
  <div>
    var1 = {{ taskState.getValueBySymbol('var1').content.number }}<br />
    var2 = {{ taskState.getValueBySymbol('var2').content.number }}<br />
    <v-form @submit.prevent="submitResponse">
      <v-input v-model="input1" />
      <v-btn type="submit"> Submit </v-btn>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'TestQuestion',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: ['input1', 'input2'],
      input1: null,
      input2: null,
    };
  },
};
</script>
